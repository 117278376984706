<template lang="pug">
  div
    show-more(v-if="firstComment" :text="firstComment" :classes="['text-ellipsis']" @click="toggleBar")
    btn(v-else @click="toggleBar" color="primary") Note / State

    portal(to="root" ref="portal")
      transition(name="aside")
        .aside(v-if="showing")
          .aside__header
            span.aside__title Note / State
            base-button(@click.native="close" icon iconSmall).unselectable
              <i class="material-icons">close</i>
          .aside__wrapper
            slot(name="send")
              checklist(
                :value="formData.type"
                :options="typesList"
                slug-key="value"
                slug-value="label"
                :error="formErrors.hasError('type')"
                :error-messages="formErrors.fields.type"
                @input="setType"
              ).application-type
              app-textarea(
                :value="formData.comment"
                @change="setComment"
                placeholder="Comment"
                :error="formErrors.hasError('comment')"
                :error-messages="formErrors.fields.comment"
              ).application-comment
              .application-datepicker(:class="{disabled: isDueDateDisabled}")
                app-datepicker(:value="formData.task_due_date" @input="setDueDate" hideDetails no-title)
                  template(v-slot:activator="{showDatepicker, formattedDate, on}")
                    app-input(
                      :class="'date__input'"
                      :iconLink="'ui/calendar.svg'"
                      :value="formattedDate"
                      placeholder="Due date"
                      :error="formErrors.hasError('task_due_date')"
                      :error-messages="formErrors.fields.task_due_date"
                      readonly="readonly"
                      v-on="on"
                      @click="showDatepicker"
                    )
              app-button(color="primary" block @click.native="submit").unselectable Save
            notes-item(v-if="logs" v-for="(log, index) in logs" :log="log" :key="index")
</template>

<script>
import Vue from 'vue'
import Btn from '@/components/global/BtnCustom.vue'
import BtnIcon from '@/components/global/BtnIcon.vue'
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from '@/util/form-errors'
import appInput from '@/components/global/actions/BaseInput.vue'
import { REAPPLY_APPLICATIONS_TYPES_LIST } from '@/app/admin/modules/reapply_applications/core/reapplyApplications-const'

export const state = Vue.observable({
  active: []
})

export default {
  name: 'ReapplyApplicationNotes',

  inject: ['reapplyService'],

  mixins: [showingMixin, errorsMixin],

  props: {
    logs: Array,
    ID: Number,
    appID: String
  },

  data: () => ({
    formData: {
      comment: '',
      task_due_date: '',
      type: ''
    },
    typesList: Object.values(REAPPLY_APPLICATIONS_TYPES_LIST),
    formErrors: new FormErrors(),
  }),

  computed: {
    firstComment() {
      if (!this.logs.length) return ''
      return this.logs[0].comment
    },

    isDueDateDisabled() {
      return this.isCanceledType || this.isReappliedType
    },

    isCanceledType() {
      return this.formData &&
        (this.formData.type === REAPPLY_APPLICATIONS_TYPES_LIST.CANCELED.value ||
        this.formData.type === REAPPLY_APPLICATIONS_TYPES_LIST.CANCELED_NO_CON.value);
    },

    isReappliedType() {
      return this.formData && (this.formData.type === REAPPLY_APPLICATIONS_TYPES_LIST.UNPAID_REAPPLIED.value || this.formData.type === REAPPLY_APPLICATIONS_TYPES_LIST.PAID_REAPPLIED.value)
    }
  },

  methods: {
    submit() {
      if (!this.validate(this.formErrors)) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      this.setNotes()
      this.sendLog()
    },

    validate(validator) {
      validator.resetFields()
      if (!this.formData.comment) validator.invalidField('comment', 'field required')
      if (!this.formData.type) validator.invalidField('type', 'option required')
      if ((!this.isCanceledType && !this.isReappliedType) && !this.formData.task_due_date) validator.invalidField('task_due_date', 'field required')

      return validator.isValid()
    },

    toggleBar() {
      if (this.showing) return this.close()
      this.open()
    },

    open() {
      if (!state.active.length) {
        state.active.push({toggle: this.toggle})
        return this.toggle()
      }
      state.active.forEach(item => item.toggle())
      setTimeout(() => {
        this.toggle()
      }, 300);
      state.active = [{toggle: this.toggle}]
    },

    close() {
      state.active = []
      this.toggle()
    },

    reset() {
      this.formData = {
        comment: '',
        task_due_date: '',
        type: ''
      }
    },

    setType(value) {
      this.formData.type = value
      this.formErrors.validField('type')
      if (value === REAPPLY_APPLICATIONS_TYPES_LIST.NO_PICK_UP.value) this.formData.task_due_date = this.$dates().add(1, 'day').toISOString()
      if (value === REAPPLY_APPLICATIONS_TYPES_LIST.CANCELED.value ||
        value === REAPPLY_APPLICATIONS_TYPES_LIST.CANCELED_NO_CON.value ||
        value === REAPPLY_APPLICATIONS_TYPES_LIST.UNPAID_REAPPLIED.value || 
        value === REAPPLY_APPLICATIONS_TYPES_LIST.PAID_REAPPLIED.value) this.formData.task_due_date = ''
    },

    setComment(value) {
      this.formData.comment = value
      this.formErrors.validField('comment')
    },

    setDueDate(value) {
      this.formData.task_due_date = this.$dates(new Date(value)).toISOString()
      this.formErrors.validField('task_due_date')
    },

    setNotes() {
      if ((this.formData.type === REAPPLY_APPLICATIONS_TYPES_LIST.UNPAID_REAPPLIED.value || this.formData.type === REAPPLY_APPLICATIONS_TYPES_LIST.PAID_REAPPLIED.value) && this.appID) {
        this.formData.comment = `${this.appID} | ${this.formData.comment}`
      }
    },

    async sendLog() {
      try {
        this.loading = true
        let res = await this.reapplyService.addLog(this.ID, this.formData)
        this.$emit('change:log', {res, task_due_date: this.formData.task_due_date})
        this.$emit('change:status');
        this.reset()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    }
  },

  watch: {
    showing() {
      if (this.showing) this.reset()
    }
  },

  components: {
    BtnIcon,
    btn: Btn,
    appInput,
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    notesItem: () => import('./ReapplyApplicationNotesItem.vue'),
    showMore: () => import('@/components/global/showMoreTooltip.vue'),
    dueDate: () => import('./ReapplyApplicationDueDate.vue'),
    appTextarea: () => import('@/components/global/actions/BaseTextarea.vue'),
    checklist: () => import('@/components/global/actions/BaseBlockChecklist.vue'),
    appDatepicker: () => import('@/app/admin/components/CrmDatepicker.vue'),
    baseButton: () => import('@/components/global/actions/BaseButton.vue')
  }
}
</script>

<style lang="scss">
.aside {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 220px;
  z-index: 1000;
  overflow-y: auto;
  background: #FEFFFF;
  box-shadow: 0 0 8px 4px rgba(216, 216, 216, 0.3);

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }

  &__wrapper {
    padding: 0 15px 15px 15px;
  }

  &__title {
    display: flex;
    align-items: center;
    font-family: $font;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 18px;
    text-transform: uppercase;
    color: $title;
  }
}

.application {
  &- {
    &type {}

    &comment {
      textarea {
        &::placeholder {
          font-family: $font !important;
          font-size: 12px !important;
          font-weight: 600 !important;
          color: #8A8A8A !important;
        }
      }
    }

    &input {
      input {
        &::placeholder {
          font-family: $font !important;
          font-size: 12px !important;
          font-weight: 600 !important;
          color: #8A8A8A !important;
        }
      }
    }
  }
}
</style>
