<template lang="pug">
  button.btn-icon(@click="click" :class="{'btn-icon_disabled' : disabled, 'btn-icon_transparent': transparent}" :style="{color: color}")
    .btn-icon__wrapper
    .btn-icon__item
      slot
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    color: String,
    transparent: Boolean
  },

  methods: {
    click() {
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
.btn-icon {
  width: 24px;
  height: 24px;

  position: relative;

  &_disabled {
    cursor: not-allowed;

    .btn-icon__wrapper {
      opacity: .2;
    }

    .btn-icon__item i {
      color: $bg-filled;
    }
  }

  &_transparent {
    .btn-icon__wrapper {
      opacity: 0;
    }

    background: transparent;
  }

  &__wrapper {
    background: $bg-filled;
    opacity: .3;
    border-radius: 4px;
    height: 100%;
    width: 100%;
  }

  &:hover {
    .btn-icon__wrapper {
      background: darken($bg-filled, 20%);
      opacity: .2;
    }
  }

  &__item {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
